import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import styles from './SearchForm.module.scss';

const token = process.env.REACT_APP_ANYTIME_TOKEN as string;

interface SearchFormProps {
    initialFromDate?: any;
    initialToDate?: any;
    initialGuests?: any;
}

type PopperState = {
    platform: any;
    placement: string;
    strategy: string;
    x: number;
    y: number;
    initialPlacement: string;
    middlewareData: any;
    rects: any;
    elements: {
        reference: HTMLElement;
        popper: HTMLElement;
    };
};

const SearchForm: React.FC<SearchFormProps> = ({
    initialFromDate = '',
    initialToDate = '',
    initialGuests = 2
}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState<any>(new Date(initialFromDate));
    const [endDate, setEndDate] = useState<any>(new Date(initialToDate));
    const [guestCount, setGuestCount] = useState(initialGuests);

    // Function to get minimum checkout date (2 days after checkin)
    const getMinCheckoutDate = (checkInDate: Date) => {
        const minCheckout = new Date(checkInDate);
        minCheckout.setDate(minCheckout.getDate() + 2);
        return minCheckout;
    };
    
    const popperModifiers = ({
        placement: "bottom"
    })


    useEffect(() => {
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);

        if (initialFromDate) {
            const initialFromDateTime = new Date(initialFromDate);
            if (initialFromDateTime < todayDate) {
                setStartDate(todayDate);
            }
        }

        if (initialToDate && initialFromDate) {
            const initialFromDateTime = new Date(initialFromDate);
            const initialToDateTime = new Date(initialToDate);
            const minCheckoutDate = getMinCheckoutDate(initialFromDateTime);

            if (initialToDateTime < minCheckoutDate) {
                setEndDate(minCheckoutDate);
            }
        }
    }, [initialFromDate, initialToDate]);

    // Handle check-in date change and update checkout if needed
    const handleCheckInChange = (date: Date | null) => {
        setStartDate(date);
        if (date && endDate) {
            const minCheckout = getMinCheckoutDate(date);
            if (endDate < minCheckout) {
                setEndDate(minCheckout);
            }
        }
    };

    const CustomInput = React.forwardRef<HTMLInputElement, any>(({ value, onClick }, ref) => (
        <input
            className={styles.input}
            value={value}
            onClick={onClick}
            ref={ref}
            placeholder="Select Date"
            readOnly
        />
    ));

    const handleSearch = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!startDate || !endDate) {
            alert('Please select both check-in and check-out dates');
            return;
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (startDate < today) {
            alert('Check-in date cannot be in the past');
            return;
        }

        // Check for minimum 2-night stay
        const minCheckout = getMinCheckoutDate(startDate);
        if (endDate < minCheckout) {
            alert('Minimum stay is 2 nights');
            return;
        }

        setLoading(true);

        const fromDate = startDate.toISOString().split('T')[0];
        const toDate = endDate.toISOString().split('T')[0];

        try {
            const response = await axios.post('https://partner-api.anytimebooking.eu/availability', {
                from_date: fromDate,
                to_date: toDate,
                unit: [59702, 58946, 59002, 59703, 59001, 58944, 58943]
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json;version=1.3',
                    'Content-Type': 'application/json',
                }
            });

            const searchParams = new URLSearchParams({
                fromDate,
                toDate,
                guests: guestCount.toString(),
                availabilityData: JSON.stringify(response.data)
            });

            navigate({
                pathname: '/search',
                search: `?${searchParams.toString()}`
            });

        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Axios error:', error.response?.data);
            } else {
                console.error('Error:', error);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSearch} className={styles.form}>
            <div className={styles.section}>
                <div className={styles.label}>Check in</div>
                <DatePicker
                    selected={startDate}
                    onChange={handleCheckInChange}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    minDate={new Date()}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select check-in date"
                    required
                    customInput={<CustomInput />}
                    wrapperClassName={styles.datePickerWrapper}
                    calendarClassName={styles.calendar}
                    popperPlacement="bottom"
                    popperModifiers={[
                        {
                            name: "placement",
                            fn(state) {
                                return state
                            }
                        }
                    ]}
                    popperClassName={styles.popper}
                    
                />
            </div>
            
            <div className={styles.section}>
                <div className={styles.label}>Check out</div>
                <DatePicker
                    selected={endDate}
                    onChange={(date: Date | null) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate ? getMinCheckoutDate(startDate) : new Date()}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select check-out date"
                    required
                    disabled={!startDate}
                    customInput={<CustomInput />}
                    wrapperClassName={styles.datePickerWrapper}
                    calendarClassName={styles.calendar}
                    popperPlacement="bottom"
                    popperModifiers={[
                        {
                            name: "placement",
                            fn(state) {
                                return state
                            }
                        }
                    ]}
                    popperClassName={styles.popper}
                />
            </div>

            <div className={styles.section}>
                <div className={styles.label}>Who</div>
                <div className={styles.guestSelector}>
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#717171" strokeWidth="2">
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <select
                        className={styles.select}
                        value={guestCount}
                        onChange={(e) => setGuestCount(Number(e.target.value))}
                    >
                        {[1, 2, 3, 4, 5].map(num => (
                            <option key={num} value={num}>{num} guest{num !== 1 ? 's' : ''}</option>
                        ))}
                    </select>
                </div>
            </div>

            <button type="submit" className={styles.button} disabled={loading}>
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
            </button>
        </form>
    );
};

export default SearchForm;