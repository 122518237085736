import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TreeHouseCard from '../../components/TreeHouseCard/TreeHouseCard';
import styles from "./SearchResults.module.scss";
import { Col, Row } from 'reactstrap';
import SearchForm from '../SearchForm/SearchForm';

interface AvailabilityItem {
    id: number;
    day: string;
    category_id: number;
    unit_id: number;
    level: number;
    start_flag: number;
}

interface DateRange {
    start: string;
    end: string;
}

interface AvailabilityInfo {
    treehouse: any;
    availableDates: string[];
    alternativeDateRanges: DateRange[];
    isFullyAvailable: boolean;
}

interface GroupedAvailability {
    [key: number]: AvailabilityItem[];
}

interface SearchResultsProps {
    structures: any[]
}

const MINIMUM_NIGHTS = 2;

const SearchResults: React.FC<SearchResultsProps> = ({ structures }) => {
    const [searchParams] = useSearchParams();
    const [availabilityResults, setAvailabilityResults] = useState<AvailabilityInfo[]>([]);
    const [parsedAvailabilityData, setParsedAvailabilityData] = useState<AvailabilityItem[]>([]);

    const fromDate = searchParams.get('fromDate');
    const toDate = searchParams.get('toDate');
    const guests = searchParams.get('guests');

    useEffect(() => {
        const availabilityDataParam = searchParams.get('availabilityData');
        if (availabilityDataParam) {
            try {
                const parsed = JSON.parse(availabilityDataParam);
                setParsedAvailabilityData(parsed);
            } catch (error) {
                console.error('Error parsing availability data:', error);
                setParsedAvailabilityData([]);
            }
        } else {
            setParsedAvailabilityData([]);
        }
    }, [searchParams]);

    const isValidCheckoutDate = (date: AvailabilityItem) => {
        return date.level >= 1 || (date.level === 0 && date.start_flag === 1);
    };

    const isDateAvailable = (date: AvailabilityItem, isLastDay: boolean) => {
        if (isLastDay) {
            return isValidCheckoutDate(date);
        }
        return date.level >= 1;
    };

    const findAvailableDateRanges = (dates: AvailabilityItem[]): DateRange[] => {
        const ranges: DateRange[] = [];
        let currentRange: any;

        dates.forEach((date) => {
            const isAvailable = isDateAvailable(date, false);
            const dateStr = date.day.split('T')[0];

            if (isAvailable) {
                if (!currentRange) {
                    currentRange = { start: dateStr, end: dateStr };
                } else {
                    currentRange.end = dateStr;
                }
            } else if (currentRange) {
                if (isValidCheckoutDate(date)) {
                    currentRange.end = dateStr;
                }

                const startDate = new Date(currentRange.start);
                const endDate = new Date(currentRange.end);
                const nights = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));

                if (nights >= MINIMUM_NIGHTS) {
                    ranges.push(currentRange);
                }
                currentRange = null;
            }
        });

        if (currentRange) {
            const startDate = new Date(currentRange.start);
            const endDate = new Date(currentRange.end);
            const nights = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));

            if (nights >= MINIMUM_NIGHTS) {
                ranges.push(currentRange);
            }
        }

        return ranges;
    };

    useEffect(() => {
        if (parsedAvailabilityData.length > 0 && structures && fromDate && toDate) {
            const groupedByUnit = parsedAvailabilityData.reduce<GroupedAvailability>((acc, curr) => {
                if (!acc[curr.unit_id]) {
                    acc[curr.unit_id] = [];
                }
                acc[curr.unit_id].push(curr);
                return acc;
            }, {});

            const availability: AvailabilityInfo[] = Object.entries(groupedByUnit)
                .map(([unitId, dates]) => {
                    const sortedDates = dates.sort((a: { day: string; }, b: { day: any; }) => a.day.localeCompare(b.day));
                    const datesInRange = sortedDates.filter((date: { day: string; }) => {
                        const dayString = date.day.split('T')[0];
                        return dayString >= fromDate && dayString <= toDate;
                    });

                    if (datesInRange.length < MINIMUM_NIGHTS) return null;

                    const isFullyAvailable = datesInRange.every((date: AvailabilityItem, index: number) =>
                        isDateAvailable(date, index === datesInRange.length - 1)
                    );

                    const alternativeDateRanges = findAvailableDateRanges(sortedDates);

                    const filteredAlternatives = isFullyAvailable
                        ? alternativeDateRanges.filter(range =>
                            range.start !== fromDate || range.end !== toDate)
                        : alternativeDateRanges;

                    const treehouse = structures.find(s => parseInt(s.id) === parseInt(unitId));
                    if (!treehouse) return null;

                    return {
                        treehouse,
                        availableDates: datesInRange.map((d: { day: string; }) => d.day.split('T')[0]),
                        alternativeDateRanges: filteredAlternatives,
                        isFullyAvailable
                    };
                })
                .filter((info): info is AvailabilityInfo =>
                    info !== null &&
                    (info.isFullyAvailable || info.alternativeDateRanges.length > 0)
                );

            setAvailabilityResults(availability);
        }
    }, [parsedAvailabilityData, structures, fromDate, toDate]);

    const formatDateRange = (range: DateRange): string => {
        const formatDate = (dateStr: string) => {
            const [year, month, day] = dateStr.split('-');
            return `${day}/${month}/${year}`;
        };
        return `${formatDate(range.start)} - ${formatDate(range.end)}`;
    };

    const formatDisplayDate = (dateString: string | null): string => {
        if (!dateString) return 'Not selected';
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    return (
        <div className="search-results-container">
            <div className={styles.searchForm}>
                <SearchForm initialFromDate={fromDate} initialToDate={toDate} initialGuests={guests} />
            </div>
            <h2>Available Treehouses</h2>
            <div className="search-params">
                <h3 className={styles.title}>Your Search</h3>
                <Row className={styles.searchParams}>
                    <Col>
                        <p><strong>Check-in:</strong> {formatDisplayDate(fromDate)}</p>
                    </Col>
                    <Col>
                        <p><strong>Check-out:</strong> {formatDisplayDate(toDate)}</p>
                    </Col>
                    <Col>
                        <p><strong>Guests:</strong> {guests}</p>
                    </Col>
                </Row>
            </div>

            {availabilityResults.length > 0 ? (
                <div className="treehouse-grid">
                    {availabilityResults
                        .filter(result => result.isFullyAvailable)
                        .map(result => (
                            <div key={`treehouse-${result.treehouse.id}`} className={styles.cards}>
                                <TreeHouseCard
                                    treehouse={result.treehouse}
                                    layout="list"
                                    propKey={result.treehouse.id}
                                />
                            </div>
                        ))}

                    {availabilityResults
                        .filter(result => !result.isFullyAvailable)
                        .map(result => (
                            <div key={`treehouse-${result.treehouse.id}-partial`} className={styles.cards}>
                                <div className={styles.partialAvailability}>
                                    <div className={styles.availabilityNote}>
                                        <p className="text-warning">Alternative Dates Available</p>
                                        {result.alternativeDateRanges.map((range, index) => (
                                            <p key={index}>Available: {formatDateRange(range)}</p>
                                        ))}
                                    </div>
                                    <TreeHouseCard
                                        treehouse={result.treehouse}
                                        layout="list"
                                        propKey={result.treehouse.id}
                                    />
                                </div>
                            </div>
                        ))}
                </div>
            ) : (
                <div className="no-results">
                    <p>No treehouses available for the selected dates.</p>
                </div>
            )}
        </div>
    );
};

export default SearchResults;