import React from 'react';
import { Link, Navigate } from "react-router-dom";
import styles from './TreeHouseCard.module.scss';
import { TbBath, TbUsers } from "react-icons/tb";
import { inflect } from "inflection";
import { MdBed } from "react-icons/md";
import { IoWifiOutline } from "react-icons/io5";
import { Button } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const initialState = {

}



class TreeHouseCard extends React.Component<any, any> {


    constructor(props: any) {
        super(props);
        //this.state = initialState;
        const layout = (typeof this.props.layout === 'undefined') ? 'grid' : this.props.layout;

        this.state = {
            layout: layout,
            treehouse: this.props.treehouse
        }
    }

    async componentDidMount() {
        /*this.setState({
            layout: this.
        })*/
    }

    handleNavigation = () => {


        this.setState(
            { navigate: true }
        )

    }

    render() {

        return (
            <> {this.state.navigate && (
                <Navigate to={'/treehouses/' + this.state.treehouse.slug} replace={true} />
            )}
                <article className={styles.TreeHouseCard + (this.state.layout === 'list' ? ' ' + styles.TreeHouseCardList : '')} data-testid="TreeHouseCard" key={this.props.key}>
                    <div className={styles.Inner}>

                        {this.state.layout === 'grid' ? (
                            <div className={styles.ImgContainer}>
                                <div style={{ backgroundImage: `url(${this.props.treehouse.image.url})` }} className={styles.Img}>
                                    <Link to={'/treehouses/' + this.props.treehouse.slug} style={{ display: 'block', height: '100%', width: '100%' }}>
                                    </Link>
                                </div>
                            </div>
                        ) : (

                            <Swiper
                                slidesPerView={1}
                                // autoplay={true}
                                navigation={true}
                                // pagination={true}
                                grabCursor={true}
                                //thumbs={{ swiper: thumbsSwiper }}
                                modules={[Navigation]}
                                height={390}
                                loop={true}
                                className={styles.ImgContainer + ' treehouseSRPSwiper'}
                            >
                                {this.props.treehouse.imagesCollection.items.map((image: any, index: React.Key | null | undefined) => (
                                    <SwiperSlide key={index} style={{ backgroundImage: `url(${image.url})` }} className={styles.Img}>
                                    </SwiperSlide>
                                ))}

                            </Swiper>
                        )}




                        <div className={styles.Info} onClick={() => this.handleNavigation()}>
                            <h4>
                                <Link to={'/treehouses/' + this.props.treehouse.slug}>
                                    {this.props.treehouse.name}
                                </Link></h4>
                            <div className={styles.attributes}>

                                <div>
                                    <TbUsers size={'2rem'} style={{ marginRight: '5px' }} />
                                    <span>{this.props.treehouse.guests + ' ' + inflect('guests', this.props.treehouse.guests)}</span>
                                </div>
                                <div>
                                    <MdBed size={'2.5rem'} style={{ marginRight: '10px' }} />
                                    <span>{this.props.treehouse.beds + ' ' + inflect('beds', this.props.treehouse.beds)}</span>
                                </div>
                                {this.props.layout === 'list' && (
                                    <div className={'d-none d-lg-flex'}>
                                        <TbBath size={'2rem'} style={{ marginRight: '5px' }} />
                                        <span>{'1 Bath'}</span>
                                    </div>
                                )}
                                <div>
                                    <IoWifiOutline size={'2.5rem'} style={{ marginRight: '5px' }} />
                                    <span>{' Wifi'}</span>
                                </div>


                            </div>

                            {this.props.layout === 'list' && (
                                <div className={styles.USP + ' d-none d-lg-flex'}>
                                    {this.props.treehouse.usp}
                                </div>
                            )}



                            <div className={styles.actions}>
                                <div className={styles.price + ' d-none d-sm-flex'}>
                                    From &pound;{this.props.treehouse.fromPrice}
                                </div>
                                <div className={styles.link}>
                                    <Link to={'/treehouses/' + this.props.treehouse.slug} style={{ width: '100%' }}>
                                        <Button color="white" className={styles.btn} >Book Now</Button>
                                    </Link>
                                </div>


                            </div>


                        </div>
                    </div>
                </article>
            </>
        )

    }
}

export default TreeHouseCard;
